.mainPageContainer{
    padding-top: 108px;
    padding-bottom:108px;
    padding-right:32px;
    padding-left:32px
}
.mainPageHeaderFont{
    font-family:'Inter';
    font-size:30px;
    font-weight:bold;
}
.mainHeaderFont{
    font-family:'Inter';
    font-size:20px;
    font-weight:bold;
    text-decoration:underline
}

.mainSubHeaderFont{
    font-family:'Inter';
    font-size:18px;
    font-weight:bold
}

.mainTextFont{
    font-family:'Inter';
    font-size:18px;
}

.teamImageStyle{
    width:140px;
    height:140px;
}

.teamSecFirstDiv{
    width: 40%;
}

.teamSecSecondDiv{
    width: 40%;
}

@media screen and (max-width:600px){
    .mainPageContainer{
        padding-top: 88px;
        padding-bottom:88px;
        padding-right:24px;
        padding-left:24px
    }
    .mainPageHeaderFont{
        font-family:'Inter';
        font-size:24px;
        font-weight:bold;
    }
    .mainHeaderFont{
        font-family:'Inter';
        font-size:18px;
        font-weight:bold;
        text-decoration:underline
    }
    
    .mainSubHeaderFont{
        font-family:'Inter';
        font-size:16px;
        font-weight:bold
    }
    
    .mainTextFont{
        font-family:'Inter';
        font-size:16px;
    }

    .teamImageStyle{
        width:80px;
        height:80px;
    }

    .teamSecFirstDiv{
        width: 70%;
    }
    
    .teamSecSecondDiv{
        width: 40%;
    }
}